import React, { Fragment } from "react"
import styled from 'styled-components'
import { Link } from 'gatsby'

const Wrapper = styled.div`
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #bbb;
  &.activeLink {
    color: darkslategrey
  }
  display: flex;
  align-items: center;
  height: 24px;
    &:hover {
    color: darkslategrey666;
  }
`
const Book = styled.span`
  border: 2px solid #bbb;
  width: 15px;
  height: 22px;
  margin-right: 6px;
`

const Book1 = styled.span`
  border: 2px solid darkslategrey;
  width: 15px;
  height: 20px;
  z-index:1;
`
const Book2 = styled.span`
  border: 2px solid darkslategrey;
  width: 15px;
  height: 20px;
  margin-left: -2px;
  margin-right: 6px;
`

const CategoryHeader = ({ active }) => {
  return (
      <Wrapper>
        <StyledLink to='/dogs' className={active === "dogs" ? "activeLink" : ''}>
          {active === "dogs" ? <Fragment><Book1 /><Book2 /></Fragment> : <Book />}
          Where the dogs run
        </StyledLink>
        <StyledLink to='/zombie' className={active === "zombie" ? "activeLink" : ''}>
          {active === "zombie" ? <Fragment><Book1 /><Book2 /></Fragment> : <Book />}
          Zombie fires
        </StyledLink>
        <StyledLink to='/babushka' className={active === "babushka" ? "activeLink" : ''}>
          {active === "babushka" ? <Fragment><Book1 /><Book2 /></Fragment> : <Book />}
          Babushka's house
        </StyledLink>
        <StyledLink to='/letters' className={active === "letters" ? "activeLink" : ''}>
          {active === "letters" ? <Fragment><Book1 /><Book2 /></Fragment> : <Book />}
          My first letters
        </StyledLink>
        <StyledLink to='/time' className={active === "time" ? "activeLink" : ''}>
          {active === "time" ? <Fragment><Book1 /><Book2 /></Fragment> : <Book />}
          As time goes by we start to fly
        </StyledLink>
        <StyledLink to='/vuleduve' className={active === "vuleduve" ? "activeLink" : ''}>
          {active === "vuleduve" ? <Fragment><Book1 /><Book2 /></Fragment> : <Book />}
          Vule duve
        </StyledLink>
        <StyledLink to='/concert' className={active === "concert" ? "activeLink" : ''}>
          {active === "concert" ? <Fragment><Book1 /><Book2 /></Fragment> : <Book />}
          Concert
        </StyledLink>
        <StyledLink to='/behind' className={active === "behind" ? "activeLink" : ''}>
          {active === "behind" ? <Fragment><Book1 /><Book2 /></Fragment> : <Book />}
          Left behind
        </StyledLink>
        <StyledLink to='/wrinkles' className={active === "wrinkles" ? "activeLink" : ''}>
          {active === "wrinkles" ? <Fragment><Book1 /><Book2 /></Fragment> : <Book />}
          Slavic wrinkles
        </StyledLink>
        {/* <StyledLink to='/about' className={active === "about" ? "activeLink" : ''}>
          {active === "about" ? <Fragment><Book1 /><Book2 /></Fragment> : <Book />}
          About
        </StyledLink> */}

      </Wrapper>
  )
}

export default CategoryHeader
