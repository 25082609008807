import React, { useState } from "react"
import styled from 'styled-components'

const Content = styled.div`
  font-size: 0.85rem;
  @media screen and (min-width: 720px) {
    font-size: 0.95rem;
  }
  @media screen and (min-width: 1000px) {
    font-size: 1.1rem;
  }  
  color: #999;
  &:hover {
    cursor: pointer;
  }
`

const Title = ({ content }) => {
  const {
    title,
    year,
    dimensions: {
      width,
      height
    },
    material
  }  = content

  const [hovered, setHovered] = useState(false)
  const toggleHover = () => {
    setHovered(!hovered)
  }

  return (
    <Content onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
      {
        hovered ? `${year} - ${material} - ${height}h by ${width}w`
          : title
      }
    </Content>
  )
}

export default Title
