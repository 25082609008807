import React, { useState } from "react"
import styled from 'styled-components'
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"

const Content = styled.div`
  height: 16vw;
  max-height: 120px;
  width: 16vw;
  max-width: 120px;
  margin: 12px;

  &:hover {
    cursor: pointer;
  }
`

const styles = {
  hovered: {
    opacity: 1
  },
  unhovered: {
    opacity: 0.3
  }
}

const Thumbnail = ({ node, onClick, current }) => {

  const [hovered, setHovered] = useState(false)
  const toggleHover = () => {
    setHovered(!hovered)
  }

  return (
    <Content onMouseEnter={toggleHover} onMouseLeave={toggleHover} onClick={onClick}>
      <GatsbyImage
        image={node.frontmatter.picture.childImageSharp.gatsbyImageData}
        style={styles.small}
        fadeIn={false}
        imgStyle={hovered || current ? styles.hovered : styles.unhovered}
      />
    </Content>
  )
}

export default Thumbnail
