import React, { useState, useRef } from "react"
import { graphql } from "gatsby"
import styled from 'styled-components'
// import Img from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { FiArrowLeftCircle, FiArrowRightCircle } from "react-icons/fi"
import Layout from '../components/layout'
import CategoryHeader from '../components/category-header'
import Title from '../components/title'
import Thumbnail from '../components/thumbnail'
import SEO from "../components/seo"
// import SwipeableViews from 'react-swipeable-views'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Detail = styled.div`
  text-align: center;
  margin-bottom: 16px;
`

const DetailBottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const PreviousIcon = styled(FiArrowLeftCircle)`
  color: #bbb;
  font-size: 32px;
  &:hover {
    cursor: pointer;
    color: #999;
  }
`

const NextIcon = styled(FiArrowRightCircle)`
  color: #bbb;
  font-size: 32px;
  &:hover {
    cursor: pointer;
    color: darkslategrey;
  }
`

const Gallery = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
`

const styles = {
  hidden: {
    visibility: 'hidden'
  }
};

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  pageContext
}) {
  const sliderRef = useRef(null);

  const { allMarkdownRemark } = data // data.markdownRemark holds our post data
  const [index, setIndex] = useState(0)
  const length = allMarkdownRemark.edges.length

  

  const handleNext = () => {
    // index === length ? setIndex(0) : setIndex(index + 1)
    index === length ? setIndex(0) : setIndex(index + 1)
    sliderRef.current.slickNext()
  }
  const handlePrevious = () => {
    index === 0 ? setIndex(length) : setIndex(index - 1)
    sliderRef.current.slickPrev()
  }
  // const handleSwipe = (idx) => {
  //   setIndex(idx)
  // }


  const showImage = (idx) => () => {
    setIndex(idx)
    sliderRef.current.slickGoTo(idx);
  }
//  const handleOnClick = index => {
//     this.slider.slickGoTo(index);
//   };


  const sliderSettings = {
    arrows: false,
    afterChange: (idx) => {
      setIndex(idx)
    }
  }


  const { node } = allMarkdownRemark.edges[index]

  return (
    <Layout active={'cat'}>
      <SEO title="Works" />
      <CategoryHeader active={pageContext.cat} />

      <Wrapper>
        <Detail>
          {/* <SwipeableViews index={index} onChangeIndex={handleSwipe}>
            {allMarkdownRemark.edges.map(({ node }, idx) =>
              <GatsbyImage key={idx} image={node.frontmatter.picture.childImageSharp.gatsbyImageData} />
            )}
          </SwipeableViews> */}

          <Slider ref={sliderRef} {...sliderSettings}>
            {allMarkdownRemark.edges.map(({ node }, idx) =>
              <GatsbyImage key={idx} image={node.frontmatter.picture.childImageSharp.gatsbyImageData} />
            )}
          </Slider>

        </Detail>

        <DetailBottomWrapper>
          <PreviousIcon
            style={index > 0 ? {} : styles.hidden}
            onClick={handlePrevious}
          />
          {node.frontmatter.is_work ?
            <Title content={node.frontmatter} /> : null
          }
          <NextIcon
            style={index < length - 1 ? {} : styles.hidden}
            onClick={handleNext}
          />
        </DetailBottomWrapper>

      </Wrapper>
      <Gallery>
        {allMarkdownRemark.edges.map(({ node }, idx) =>
          <Thumbnail key={idx} onClick={showImage(idx)} node={node} current={idx===index} />
        )}
      </Gallery>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($cat: String!) {
    allMarkdownRemark(filter: { frontmatter: { category: { eq: $cat }, publish: {eq: true} } }, sort: {fields: frontmatter___publish_order}) {
      edges {
        node {
          id
          frontmatter {
            title
            is_work
            year
            dimensions {
              width
              height
            }
            material
            picture {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`
